import PaymentTypeWithIcon from 'Components/Workflow/PaymentTypeWithIcon/PaymentTypeWithIcon';
import Price from 'Components/Workflow/Price';
import i18next from 'i18next';
import { inject, observer } from 'mobx-react';
import { AddonEvent } from 'Models/UI/AddonEvent';
import { AddonTransferOnewayOutbound } from 'Models/UI/AddonTransferOnewayOutbound';
import { AddonTransferOnewayInbound } from 'Models/UI/AddonTransferOnewayInbound';
import { AddonTransferRoundtrip } from 'Models/UI/AddonTransferRoundtrip';
import { BookingItemInterface } from 'Models/UI/BookingItemInterface';
import { ItemType } from 'Models/UI/ItemType';
import { PaymentType } from 'Models/UI/PaymentType';
import React from 'react';
import { FaInfoCircle } from 'react-icons/all';
import ReactTooltip from 'react-tooltip';
import { AppService } from 'State/AppService';
import { Keys } from 'Translation/translations.de.json.keys';
import { Flight } from '../../Models/UI/Flight';
import { Workflow, WorkflowService } from '../../State/WorkflowService';
import pepxpressStyles from './CartItems-pepxpress.module.scss';
import pepxciteStyles from './CartItems-pepxcite.module.scss';
import { withTheme } from '../../Context/withTheme';
import { ThemeProps } from '../../Context/AppContext';

// const Key = Keys.COMPONENTS.Cart.Item;
@inject('app')
@inject('workflow')
@observer
class CartItems extends React.Component<
  {
    app?: AppService;
    items: BookingItemInterface[];
    totalAmount?: number;
    depositAmount?: number | null;
    paymentType?: PaymentType | null;
    workflow?: WorkflowService;
  } & ThemeProps,
  {}
> {
  private KEY_PREFIX = 'cartItem_';

  render() {
    const styles = this.props.theme === 'pepxcite' ? pepxciteStyles : pepxpressStyles;
    return (
      <>
        <div className={styles.items}>
          {this.props.workflow!.workflowName !== Workflow.HOTEL_FLIGHT && this.rooms.length > 0 && (
            <div className={styles.itemGroup}>
              {this.rooms.map((item, index) => this.renderItem(item, index, styles))}
            </div>
          )}

          {this.addons.length > 0 && (
            <div className={styles.itemGroup}>
              {this.addons.map((item, index) => this.renderItem(item, index, styles))}
            </div>
          )}

          {this.train.length > 0 && (
            <div className={styles.itemGroup}>
              <div>{i18next.t(Keys.COMPONENTS.Cart.Item.train)}</div>
              {this.train.map((item, index) => this.renderItem(item, index, styles))}
            </div>
          )}

          {this.insurance.length > 0 && (
            <div className={styles.itemGroup}>
              {this.insurance.map((item, index) => this.renderItem(item, index, styles))}
            </div>
          )}

          {this.charges.length > 0 && (
            <div className={styles.itemGroup}>
              {this.charges.map((item, index) => this.renderItem(item, index, styles))}
            </div>
          )}
		  
          {this.payment.length > 0 && (
            <div className={styles.itemGroup}>
              {this.payment.map((item, index) => this.renderItem(item, index, styles))}
            </div>
          )}

          {this.deposits.length > 0 ||
            (this.props.workflow!.workflowName !== Workflow.HOTEL_FLIGHT && this.earlyBird.length > 0) && (
            <div className={styles.itemGroup}>
              {this.deposits.length > 0 &&
                this.deposits.map((item, index) => this.renderItem(item, index, styles))}
              {this.props.workflow!.workflowName !== Workflow.HOTEL_FLIGHT &&
                this.earlyBird.length > 0 &&
                this.earlyBird.map((item, index) => this.renderItem(item, index, styles))}
            </div>
          )}

          {this.flexPep.length > 0 && (
            <div className={styles.itemGroup}>
              {this.flexPep.map((item, index) => this.renderItem(item, index, styles))}
            </div>
          )}

          {this.isSumVisible && (
            <div className={styles.itemGroup}>
              {this.props.totalAmount && (
                <div
                  className={styles.item + ' ' + styles.itemBold}
                  key={this.KEY_PREFIX + 'totalAmount'}
                >
                  <div className={styles.name}>{i18next.t(Keys.COMPONENTS.Cart.Item.total)}</div>
                  <div className={styles.price}>
                    {this.props.app!.isLoading ? (
                      <span>...</span>
                    ) : (
                      <Price amount={this.props.totalAmount} />
                    )}
                  </div>
                </div>
              )}

              {(this.props.depositAmount !== undefined && this.props.depositAmount !== null) && (
                <div
                  className={styles.item + ' ' + styles.itemBold}
                  key={this.KEY_PREFIX + 'paymentType'}
                >
                  <div className={styles.name}>{i18next.t(Keys.COMPONENTS.Cart.Item.deposit)}</div>
                  <div className={styles.price}>
                    {this.props.app!.isLoading ? (
                      <span>...</span>
                    ) : (
                      <Price amount={this.props.depositAmount} />
                    )}
                  </div>
                </div>
              )}

              {this.props.paymentType && (
                <div className={styles.item} key={this.KEY_PREFIX + 'deposit'}>
                  <div className={styles.name}>
                    {i18next.t(Keys.COMPONENTS.Cart.Item.paymentMethod)}{' '}
                    <PaymentTypeWithIcon pt={this.props.paymentType} />
                  </div>
                  <div className={styles.price}></div>
                </div>
              )}
            </div>
          )}
        </div>
      </>
    );
  }

  renderItem(item: BookingItemInterface, i: number, styles: { [key: string]: string }) {
    const count =
      item.type === ItemType.ROOM && item.count && item.count > 1 ? item.count + 'x ' : '';
    const key = this.KEY_PREFIX + '_id' + item.id + '_i' + i;
    const details = this.renderDetails(item, styles);

    return (
      <div className={styles.item} key={key}>
        <div className={styles.name}>{count + item.title}</div>
        <div className={styles.price}>
          {details && <FaInfoCircle className={styles.infoIcon} data-tip data-for={'tip_' + key} />}
          <Price amount={item.totalPrice} />
        </div>
        {details && <ReactTooltip id={'tip_' + key}>{details}</ReactTooltip>}
      </div>
    );
  }

  renderDetails(i: BookingItemInterface, styles: { [key: string]: string }) {
    if (i instanceof AddonEvent) {
      return (
        <div className={styles.details}>
          <div className={styles.detail}>
            <span>{i18next.t(Keys.COMPONENTS.Cart.Item.day)}</span>
            <span>{i.selectedDate || i18next.t(Keys.COMPONENTS.Cart.Item.select)}</span>
          </div>
          <div className={styles.detail}>
            <span>{i18next.t(Keys.COMPONENTS.Cart.Item.time)}</span>
            <span>{i.selectedVariant || i18next.t(Keys.COMPONENTS.Cart.Item.select)}</span>
          </div>
          {!!i?.addonInfo?.addonInfoSeats &&
            i?.addonInfo?.addonInfoSeats?.length > 0 &&
            i.addonInfo.addonInfoSeats.map(value => <div className={styles.detail}>{value}</div>)}
        </div>
      );
    } else if (i instanceof AddonTransferRoundtrip) {
      return (
        <div className={styles.details}>
          <div className={styles.detail}>
            <strong>{i18next.t(Keys.COMPONENTS.Cart.Item.outwardTravel)}</strong>
          </div>
          <div className={styles.detail}>
            <span>{i18next.t(Keys.COMPONENTS.Cart.Item.flightNumber)}</span>
            <span>{i.inboundNr || i18next.t(Keys.COMPONENTS.Cart.Item.select)}</span>
          </div>
          <div className={styles.detail}>
            <span>{i18next.t(Keys.COMPONENTS.Cart.Item.date)}</span>
            <span>{i.inboundDate || i18next.t(Keys.COMPONENTS.Cart.Item.select)}</span>
          </div>
          <div className={styles.detail}>
            <span>{i18next.t(Keys.COMPONENTS.Cart.Item.time)}</span>
            <span>{i.inboundTime || i18next.t(Keys.COMPONENTS.Cart.Item.select)}</span>
          </div>
          <div className={styles.detail}>
            <strong>{i18next.t(Keys.COMPONENTS.Cart.Item.returnTravel)}</strong>
          </div>
          <div className={styles.detail}>
            <span>{i18next.t(Keys.COMPONENTS.Cart.Item.flightNumber)}</span>
            <span>{i.outboundNr || i18next.t(Keys.COMPONENTS.Cart.Item.select)}</span>
          </div>
          <div className={styles.detail}>
            <span>{i18next.t(Keys.COMPONENTS.Cart.Item.date)}</span>
            <span>{i.outboundDate || i18next.t(Keys.COMPONENTS.Cart.Item.select)}</span>
          </div>
          <div className={styles.detail}>
            <span>{i18next.t(Keys.COMPONENTS.Cart.Item.time)}</span>
            <span>{i.outboundTime || i18next.t(Keys.COMPONENTS.Cart.Item.select)}</span>
          </div>
        </div>
      );
    } else if (
      i instanceof AddonTransferOnewayOutbound ||
      i instanceof AddonTransferOnewayInbound
    ) {
      return (
        <div className={styles.details}>
          <div className={styles.detail}>
            <span>{i18next.t(Keys.COMPONENTS.Cart.Item.flightNumber)}</span>
            <span>{i.inboundNr || i18next.t(Keys.COMPONENTS.Cart.Item.enter)}</span>
          </div>
          <div className={styles.detail}>
            <span>{i18next.t(Keys.COMPONENTS.Cart.Item.date)}</span>
            <span>{i.inboundDate || i18next.t(Keys.COMPONENTS.Cart.Item.select)}</span>
          </div>
          <div className={styles.detail}>
            <span>{i18next.t(Keys.COMPONENTS.Cart.Item.time)}</span>
            <span>{i.inboundTime || i18next.t(Keys.COMPONENTS.Cart.Item.select)}</span>
          </div>
        </div>
      );
    }
  }

  get rooms() {
    try {
      return this.allItems.filter(i => i.type === ItemType.ROOM);
    } catch (e) {
      return [];
    }
  }

  get addons() {
    try {
      return this.allItems.filter(
        i =>
          i.type === ItemType.TRANSFER_ONEWAY_OUTBOUND ||
          i.type === ItemType.TRANSFER_ONEWAY_INBOUND ||
          i.type === ItemType.TRANSFER_ROUNDTRIP ||
          i.type === ItemType.CATERING ||
          i.type === ItemType.VISA ||
          i.type === ItemType.EVENT ||
          i.type === ItemType.WELLNESS ||
          i.type === ItemType.MISC ||
          i.type === ItemType.TRIP ||
          i.type === ItemType.DINNER ||
          i.type === ItemType.ARRIVAL_BY_BUS
      );
    } catch (e) {
      return [];
    }
  }

  get train() {
    try {
      return this.allItems.filter(i => i.type === ItemType.TRAIN);
    } catch (e) {
      console.log('CartItems: Could not get train.', e);
      return [];
    }
  }

  get insurance() {
    try {
      return this.allItems.filter(i => i.type === ItemType.INSURANCE);
    } catch (e) {
      console.log('CartItems: Could not get insurance.', e);
      return [];
    }
  }

  get charges() {
    try {
      return this.allItems.filter(i => i.type === ItemType.CHARGES);
    } catch (e) {
      console.log('CartItems: Could not get charges.', e);
      return [];
    }
  }
  
  get payment() {
    try {
      return this.allItems.filter(
        i =>
          i.type === ItemType.DONATION ||
          i.type === ItemType.BONUSCARD_ORDER ||
          i.type === ItemType.BONUSCARD_SAVING ||
          i.type === ItemType.COUPON
      );
    } catch (e) {
      console.log('CartItems: Could not get payment.', e);
      return [];
    }
  }

  get deposits() {
    try {
      return this.allItems.filter(i => i.type === ItemType.SPECIAL);
    } catch (e) {
      console.log('CartItems: Could not get deposits.', e);
      return [];
    }
  }

  get earlyBird() {
    try {
      return this.allItems.filter(i => i.type === ItemType.EARLY_BIRD);
    } catch (e) {
      console.log('CartItems: Could not get Early Bird.', e);
      return [];
    }
  }

  get isSumVisible() {
    return this.props.totalAmount;
  }

  get flights() {
    try {
      return this.allItems.filter(i => i.type === ItemType.FLIGHT) as Flight[];
    } catch (e) {
      console.log('CartItems: Could not get flights.', e);
      return [];
    }
  }

  get flexPep() {
    try {
      return this.allItems.filter(i => i.type === ItemType.FLEX_PEP);
    } catch (e) {
      console.log('CartItems: Could not get flex pep.', e);
      return [];
    }
  }

  get allItems() {
    try {
      if (this.props.items.length > 0) {
        return this.props.items.filter(
          i =>
            !!i.id &&
            !i.id.startsWith('d-') &&
            !i.id.startsWith('122') &&
            !i.id.startsWith('train-group')
        ); // TODO move into model(s). nothing beats a well designed api
      }
    } catch (e) {
      console.log('CartItems: Could not get allItems.', e);
    }
    return [];
  }
}
export default withTheme(CartItems);
