import i18next from 'i18next';
import React from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { IoIosCheckboxOutline } from 'react-icons/io';

import { ItemType } from 'Models/UI/ItemType';
import { PaymentType } from 'Models/UI/PaymentType';
import { WorkflowService } from 'State/WorkflowService';
import CartItems from 'Components/Workflow/CartItems';
import CartImage from 'Components/Workflow/CartImage';
import CartMainItem from 'Components/Workflow/CartMainItem';
import CartIncludedItems from 'Components/Workflow/CartIncludedItems';
import { conditionalStyles, join } from 'Helpers/conditionalStyles';
import { Keys } from 'Translation/Setup';
import pepxpressStyles from './Cart-pepxpress.module.scss';
import pepxciteStyles from './Cart-pepxcite.module.scss';
import { ThemeProps } from '../../Context/AppContext';
import { withTheme } from '../../Context/withTheme';

interface Props {
  expanded?: boolean;
  workflow?: WorkflowService;
  bookingOnRequest: boolean;
}

@inject('workflow')
@observer
class Cart extends React.Component<Props & ThemeProps, {}> {
  render() {
    const styles = this.props.theme === 'pepxcite' ? pepxciteStyles : pepxpressStyles;
    const cs = conditionalStyles(styles);
    const expanded = this.props.expanded || false;

    // Safely check if workflow and booking exist and have totalPrice
    const hasValidTotalPrice = this.props.workflow && 
                              this.props.workflow.booking && 
                              typeof this.props.workflow.booking.totalPrice !== 'undefined';
    
    return (
      <div
        className={cs('cart', {
          'cart--expanded': expanded,
          'cart--narrow': !expanded
        })}
      >
        <div className={styles.image}>
          <CartImage />
          {this.props.expanded && (
            <div className={join(styles.included, styles.hideInSmall)}>
              <div className={styles.incl}>{i18next.t(Keys.COMPONENTS.Cart.included)}</div>
              <CartIncludedItems items={this.items} />
            </div>
          )}
        </div>

        <div className={styles.allItems}>
          <div className={styles.mainItem}>
            <CartMainItem />
          </div>
          {hasValidTotalPrice && this.props.workflow!.booking!.totalPrice !== 0 && (
            <div className={styles.items}>
            <CartItems
              items={this.items}
              totalAmount={this.props.workflow!.booking!.totalPrice}
              paymentType={this.paymentType}
              depositAmount={this.depositAmount}
            />
          </div>
          )}
        </div>

        {this.props.expanded && (
          <div className={join(styles.included, styles.showInSmall)}>
            <h5>{i18next.t(Keys.COMPONENTS.Cart.included)}</h5>
            <CartIncludedItems items={this.items} />
          </div>
        )}
        <div className={cs('status', {
          'status--request': this.props.bookingOnRequest,
          'status--binding': !this.props.bookingOnRequest
        })}>
          <IoIosCheckboxOutline/>
          <span>
            {this.props.bookingOnRequest ?
              i18next.t(Keys.COMPONENTS.Cart.requestBooking) :
              i18next.t(Keys.COMPONENTS.Cart.bindingBooking)}
          </span>
        </div>
      </div>
    );
  }

  @computed
  get items() {
    return this.props.workflow!.selectedItems;
  }

  @computed
  get meta() {
    return this.props.workflow!.currentMeta;
  }

  get paymentType(): PaymentType | null {
    try {
      return this.items.filter(i => i.type === ItemType.PAYMENT)[0] as PaymentType;
    } catch (e) {
      return null;
    }
  }

  get depositAmount(): number | null {
    try {
      return this.meta.payment.deposit;
    } catch (e) {
      return null;
    }
  }
  /* get depositAmount(): number | null {
    try {
      return this.items.filter(i => i.type === ItemType.DEPOSIT)[0].totalPrice;
    } catch (e) {
      return null;
    }
  } */
}
export default withTheme(Cart);
